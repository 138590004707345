import React from 'react'
import classNames from 'classnames'
import { string, bool, func, objectOf, any, arrayOf, node, oneOfType } from 'prop-types'

const getChecked = (textType, radioType, radioValue, info, field) => {
  if (textType) {
    return false
  }
  if (radioType) {
    if (info?.financeCode && radioValue?.code ? info?.financeCode === radioValue?.code || radioValue : radioValue) {
      return info ? info[field] : null
    }
    return info ? !info[field] : null
  }
  return info ? info[field] : null
}

const getValue = (textType, radioType, radioValue, info, field) => {
  if (textType) {
    return info[field]
  }
  if (radioType) {
    return radioValue
  }
  return ''
}

const Input = ({
  className,
  type,
  field,
  id,
  label,
  info,
  setInfo,
  afterComponent,
  name,
  radioValue,
  invalidFields,
  textType,
  radioType,
  placeholder,
  required,
  parentRef,
  noLabel = false,
  disabled = false,
  testID = '',
}) => {
  const invalid = invalidFields && (invalidFields.includes(field) || invalidFields.includes(className))
  const classes = classNames(className, {
    invalid,
  })
  return (
    <>
      <input
        type={type}
        id={id || field}
        className={classes || className}
        placeholder={textType ? placeholder : ''}
        aria-required={required}
        aria-invalid={!!(required && classes)}
        aria-describedby={invalid ? `${field || className}-error` : null}
        value={getValue(textType, radioType, radioValue, info, field)}
        checked={getChecked(textType, radioType, radioValue, info, field)}
        name={radioType ? name : label}
        onChange={e => (!radioType ? setInfo(textType ? e.target.value : e.target.checked, field) : null)}
        onClick={() => (radioType ? setInfo(radioValue, field) : null)}
        ref={parentRef}
        disabled={disabled}
        data-testid={testID}
      />
      {!noLabel && <label htmlFor={id || field}>{afterComponent && afterComponent}</label>}
    </>
  )
}

Input.propTypes = {
  className: string,
  type: string,
  field: string,
  id: string,
  label: string,
  info: oneOfType([string, objectOf(any)]),
  setInfo: func,
  afterComponent: node,
  name: string,
  radioValue: oneOfType([string, objectOf(any)]),
  invalidFields: arrayOf(string),
  textType: bool,
  radioType: bool,
  placeholder: string,
  required: bool,
  parentRef: any,
  noLabel: bool,
  disabled: bool,
  testID: string,
}

export default Input
