import { getFromBrowserStorage } from './storage'
import { getCurrentLocation } from './geo-location'
import { getCartTotal, getCart } from './cart'
import { getRemainingTotal } from './checkout/global'
// eslint-disable-next-line import/no-unresolved
import financePlanFeed from '../generators/data/finance-plan-feed.json'

const descendingPriority = (a, b) => a?.priority - b?.priority
const hasDownPayment = plan => plan.downPaymentRequired
const hasNoDownPayment = plan => !plan.downPaymentRequired
const checkOrderForAppliances = order => {
  const { cartItems } = getCart() ?? []
  if (cartItems?.some(item => item?.product?.category.endsWith('appliances'))) return true

  const ord = order ? { ...order } : getFromBrowserStorage('session', 'order')
  return ord?.lineItems?.some(item => item?.category?.endsWith('appliances')) ?? false
}

const getDropshipTotal = order => {
  let dropshipTotal = 0
  const lineItems = order?.lineItems
  if (lineItems) {
    const dropshipItems = lineItems.filter(item => item.deliveryType === 'O')
    dropshipTotal = dropshipItems.reduce((sum, item) => sum + item.unitPrice * item.quantity, 0)
  }
  return dropshipTotal
}

const getPrimaryPlan = plans => {
  let primaryPlan = {}
  if (plans.length > 1) {
    for (let i = 0; i < plans.length; i++) {
      if (
        JSON.stringify(primaryPlan) === '{}' ||
        plans[i].threshold > primaryPlan.threshold ||
        plans[i].dropshipThreshold > primaryPlan.dropshipThreshold
      ) {
        primaryPlan = plans[i]
      }
    }
  } else {
    // eslint-disable-next-line prefer-destructuring
    primaryPlan = plans[0]
  }
  return primaryPlan
}

export const getFinancingPriority = (financePlans, total, noThreshold) => {
  const order = getFromBrowserStorage('session', 'order')
  const dropshipTotal = getDropshipTotal(order)
  let modifiedTotal = total
  if (order && order.promotions && order.promotions.totalSavings) {
    modifiedTotal -= order.promotions.totalSavings
  }
  const newFinancePlans = []

  const financePlanCodes = Array.from(
    financePlans.reduce((set, financePlan) => {
      set.add(financePlan.financeCode)
      return set
    }, new Set()),
  )

  for (let i = 0, n = financePlanCodes.length; i < n; i++) {
    let priority = 0
    let index = 0
    const plans = financePlans.filter(plan => plan?.financeCode === financePlanCodes[i])
    for (let x = 0; x < plans.length; x++) {
      const planPriority = parseInt(plans[x]?.priority)
      if (priority < planPriority) {
        priority = planPriority
        index = x
      }
    }
    if (
      (noThreshold ||
        (modifiedTotal || order?.total || 0) >= plans[index].threshold ||
        (dropshipTotal >= plans[index].dropshipThreshold && plans[index].dropshipThreshold > 0)) &&
      newFinancePlans.filter(plan => plan?.financeCode === plans[index].financeCode).length < 1
    ) {
      if (plans[index].downPaymentRequired) {
        newFinancePlans.unshift(plans[index])
      } else if (!plans[index].downPaymentRequired) {
        newFinancePlans.push(plans[index])
      }
    }
  }
  const downPaymentPlans = newFinancePlans.filter(hasDownPayment).sort(descendingPriority)
  const normalPlans = newFinancePlans.filter(hasNoDownPayment).sort(descendingPriority)
  const primaryDownPayment = getPrimaryPlan(downPaymentPlans)
  const primaryNormal = getPrimaryPlan(normalPlans)
  const genesisPlan = newFinancePlans.filter(plan => plan?.financeCode === 'GENESIS')[0]
  return [primaryDownPayment, primaryNormal, genesisPlan]
}

/**
 * @returns {Array} finance plans for current region of user
 */
export const getFinancePlansFromRegion = () => {
  let financePlans = []
  if (financePlanFeed) {
    const loc = getCurrentLocation()
    if (loc) {
      financePlans = financePlanFeed[`${loc.region.toLowerCase()}${loc.price_zone}`]
        ? financePlanFeed[`${loc.region.toLowerCase()}${loc.price_zone}`]
        : financePlanFeed[`${loc.region.toLowerCase()}0`] || financePlanFeed.fl0
    } else {
      financePlans = financePlanFeed.fl0
    }
  }
  return financePlans
}

export const getFinancePlans = (price = 0, isCheckout = false, noThreshold = false) => {
  let realFinancePlans = []
  const financePlans = getFinancePlansFromRegion()
  if (financePlans.length > 0) {
    realFinancePlans = financePlans
    if (realFinancePlans && realFinancePlans.length > 0) {
      const hasAppliances = checkOrderForAppliances()
      const cartTotal = getCartTotal(getCart())
      const orderValues = getRemainingTotal(false, cartTotal, false, false)
      const totalForFin = orderValues.total + price
      if (hasAppliances) {
        realFinancePlans = realFinancePlans.filter(
          p => p.name.startsWith('12') && (!isCheckout || p.threshold <= totalForFin),
        )
      } else {
        realFinancePlans = getFinancingPriority(financePlans, isCheckout ? orderValues.total : totalForFin, noThreshold)
      }
    }
  }
  return realFinancePlans
}

/**
 *
 * @param {*} price product price
 * @param {*} deliveryType product delivery type
 * @returns {Object}
 * @property {number} financeAmount Finance Amount to pay each month
 * @property {bool} showFinance whether to showFinance or not
 */
export const MIN_FINANCE_AMOUNT = 5
export const productFinancing = (price, deliveryType) => {
  let finance = { financeAmount: 0, showFinance: false, financeNumMonths: 0 }
  const realFinancePlans = getFinancePlans(price, false, true)
  if (realFinancePlans.length > 0) {
    const priorityZeroPlan = realFinancePlans.find(plan => plan?.priority === 0)
    if (priorityZeroPlan) {
      const priceExceedsThreshold =
        price >= priorityZeroPlan.threshold ||
        (deliveryType === 'O' && priorityZeroPlan.dropshipThreshold > 0 && price >= priorityZeroPlan.dropshipThreshold)
      if (!priceExceedsThreshold) {
        return finance
      }
    }
    const finForCalc = realFinancePlans.filter(plan => plan && plan.downPaymentRequired)
    if (finForCalc) {
      let financeAmount = 0
      let financeNumMonths = 0
      if (finForCalc[0].numberOfMonths > 0) {
        financeAmount = Math.ceil(price / parseInt(finForCalc[0].numberOfMonths))
        financeNumMonths = finForCalc[0].numberOfMonths
      }
      finance = {
        financeAmount,
        financeNumMonths,
        showFinance: financeAmount >= MIN_FINANCE_AMOUNT,
      }
    }
  }
  return finance
}

export const getFinanceMarketingMessageData = () => {
  const financePlans = getFinancePlans()?.filter(plan => plan && typeof plan !== 'undefined')
  if (financePlans && financePlans.length > 0) {
    const downPayment = financePlans.filter(plan => plan && typeof plan !== 'undefined' && plan.downPaymentRequired)
    if (downPayment && downPayment.length > 0) {
      return {
        siteFriendlyLabel: downPayment[0].siteFriendlyLabel,
        marketingMessage:
          downPayment[0].marketingMessage &&
          downPayment[0].marketingMessage.markdown &&
          downPayment[0].marketingMessage.markdown.childMarkdownRemark &&
          downPayment[0].marketingMessage.markdown.childMarkdownRemark.html,
      }
    }
    return (
      financePlans[0] && {
        siteFriendlyLabel: financePlans[0].siteFriendlyLabel,
        marketingMessage:
          financePlans[0].marketingMessage &&
          financePlans[0].marketingMessage.markdown &&
          financePlans[0].marketingMessage.markdown.childMarkdownRemark &&
          financePlans[0].marketingMessage.markdown.childMarkdownRemark.html,
      }
    )
  }
  return {
    siteFriendlyLabel: '',
    marketingMessage: '',
  }
}

export const getFinancePlansByOrder = order => {
  let financePlans = []
  if (financePlanFeed) {
    const loc = order.Division
    if (loc) {
      financePlans = financePlanFeed[`${loc.toLowerCase()}0}`] || financePlanFeed.fl0
    } else {
      financePlans = financePlanFeed.fl0
    }
  }
  return financePlans
}
